import React from "react";
import { Link } from "react-router-dom";

function Navbar(props) {
    return (
        <div className="container">
            <nav className="navbar navbar-expand-lg fixed-top navbar-dark" role="navigation">
                <div className="container">
                    <a className="navbar-brand" href="/">{props.brand}</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            {/* <li className="nav-item">
                                <Link to="/" className="nav-link active" aria-current="page">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="../pages/Dev" className="nav-link">Dev</Link>

                            </li> */}
                            {/* {props.links.map((link) => 
                            <li className="nav-item">   
                                <a className="nav-link" href="/">Dev</a>
                            </li>
                            )} */}
                        </ul>
                    </div>
                </div>
            </nav>

        </div>
    )
}

export default Navbar;