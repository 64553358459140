import React from 'react';

function Home() {
    return (
        <div className="container">
            <h1>Sam Pak - a software engineer.</h1>
            {/* <div className='container'><hr /></div> */}
            {/* <h2> Environmental engineer turned software developer.</h2> */}
            <br></br>
            <p> Previously at <a href="https://rr.ai/" style={{color: "rgba(255, 255, 255, 0.55)"}}>Robotic Research</a> and  <a href="https://www.octo.us/" style={{color: "rgba(255, 255, 255, 0.55)"}}>Octo</a>.</p>
            {/* <p>Resume, Github, Email.</p> */}
        </div>
    )
}

export default Home;