import React from "react";


function NoPage(){
    return (
        // <div className="container">
        <h1>404 Page Not Found!</h1>
        // </div>
    )
}

export default NoPage;