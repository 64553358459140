import './App.css';

import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';

import Layout from './components/Layout';
import Home from './pages/Home'
import Dev from './pages/Dev'
import NoPage from './pages/NoPage';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            {/* <Route path="pages/Dev" element={<Dev/>}/> */}
            <Route path="*" element={<NoPage />}/>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
