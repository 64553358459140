import React from "react";

function Footer() {
    const year = () => {
        let date = new Date()
        return date.getFullYear()
    }
    return (
        <div className="container">
            <footer className="py-1 footer">
                <div className="row">
                    <ul className="nav d-flex justify-content-between">
                        <li className="nav-item p-2"><a href="https://www.linkedin.com/in/samuelcpak" className="nav-link p-0 text-muted">LinkedIn</a></li>
                        <li className="nav-item p-2"><a href="https://github.com/sc-pak" className="nav-link p-0 text-muted">GitHub</a></li>
                        <li className="nav-item p-2"><a href="https://gtvault-my.sharepoint.com/:w:/g/personal/spak6_gatech_edu/EaTuyadk9SdDn7IxWXn2eXcBdex8BTYmhuwe8JOdSbrS4w?e=HQ2z74" className="nav-link p-0 text-muted">Resume</a></li>
                        {/* <li className="nav-item p-2"><a href="mailto: hi@samcpak.com" className="nav-link p-0 text-muted">Email</a></li> */}
                        <li className="nav-item p-2 ml-auto">©{year()} Sam Pak.</li>
                    </ul>
                </div>
            </footer>
        </div>
    )
}

export default Footer